import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { FC, useEffect } from 'react'

import Accordion from '@/components/Accordion'
import Heading from '@/components/Heading'

import styles from './Texts.module.scss'
import { useTexts } from '@/bus/texts'
import { Content } from '@/layouts'

interface TextsComponentProps {
  slug: string
}

const regexp = new RegExp(/style="(.*?)"/gm)

export const Texts: FC<TextsComponentProps> = ({ slug = null }) => {
  const { getTextsBySlug, data: { header = '', text = '', items = [] } = {} } =
    useTexts()

  const {
    t,
    i18n: { language: lang }
  } = useTranslation()

  useEffect(() => {
    if (!slug) {
      return
    }
    getTextsBySlug({ slug, lang })
  }, [])

  if (!slug) {
    return null
  }

  return (
    <>
      <NextSeo title={t(`${slug}:head.title`) as string} />
      <Content>
        <div className={styles['texts']}>
          <Heading className={styles['texts__heading']} level={1}>
            {header}
          </Heading>
          <div
            className={styles['texts__content']}
            dangerouslySetInnerHTML={{
              __html: text.replace(regexp, '').replace(/&zwj;/g, '')
            }}
          />
          {items?.length > 0 && <Accordion items={items} />}
        </div>
      </Content>
    </>
  )
}
