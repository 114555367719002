import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import WarningMessage from '@/components/WarningMessage'

import styles from './CheckEmail.module.scss'
import { useUser } from '@/bus/user'

export const CheckEmail: FC = () => {
  const { t } = useTranslation()
  const { data: { recoveryEmail: email = null } = {} } = useUser()
  return (
    <div className={styles['check-email']}>
      <Heading className={styles['check-email__heading']} level={3}>
        {t('check-email:heading')}
      </Heading>
      <div
        className={styles['check-email__summary']}
        dangerouslySetInnerHTML={{
          __html: t('check-email:summary', { email })
        }}
      />
      <div>
        <WarningMessage before={<Icon name={'information/24'} />}>
          <span
            dangerouslySetInnerHTML={{
              __html: t('check-email:message', {
                email: 'no-reply@adsimple.me'
              })
            }}
          />
        </WarningMessage>
      </div>
    </div>
  )
}
